<template>
    <dashboard-layout>
        <dashboard-body></dashboard-body>
    </dashboard-layout>
</template>
<script>
const DashboardLayout = () => import("../../layouts/DashboardLayout");
const DashboardBody = () => import("../../components/dashboard/DashboardBody");

export default {
    components: {
        DashboardLayout,
        DashboardBody
    }
}
</script>
